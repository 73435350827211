import { getRequest, postRequest, putRequest, deleteRequest } from '../request'

export const getSendInfoList = (res) => {
    return getRequest('/sendinfo/sendinfos', res)
}

export const getSendInfoListPage = (res) => {
    return postRequest('/sendinfo/sendinfos', res)
}

export const getSendResultListPage = (res) => {
    return postRequest('/sendresult/sendresults', res)
}

export const getConsumeListPage = (res) => {
    return postRequest('/sendresult/consumes', res)
}

export const saveSendInfo = (res) => {
    if (res.id) {
        //更新短信
        return putRequest('/sendinfo/sendinfo', res);
    }
    else {
        //添加短信
        return postRequest('/sendinfo/sendinfo', res);
    }
}

export const deleteSendInfo = (res) => {
    return deleteRequest('/sendinfo/sendinfo/' + res.id + "?logic=" + res.logic)
}

export const doSendInfo = (res) => {
    return postRequest('/sendinfo/sendinfo/send', res)
}