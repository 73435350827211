import { getRequest, postRequest, putRequest } from '../request'

export const getAccountInfoList = (res) => {
    return getRequest('/account/accounts', res)
}

export const getAccountInfoListPage = (res) => {
    return postRequest('/account/accounts', res)
}

export const saveAccountInfo = (res) => {
    if (res.id) {
        //更新App用户信息
        return putRequest('/account/account', res);
    }
    else {
        //添加App用户
        return postRequest('/account/account', res);
    }
}

export const updateAuthorizeStatus = (res) => {
    return putRequest('/account/account/status', res)
}

export const updateAppUserKey = (res) => {
    return putRequest('/account/account/key', res)
}
