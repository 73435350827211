<!-- 短信信息页面（新增、编辑、详情） -->
<template>
  <div>
    <a-modal
      :title="
        isCreate == 0 ? '添加短信' : isCreate == 1 ? '编辑短信' : '短信详情'
      "
      width="60%"
      okText="确定"
      cancelText="取消"
      v-model:visible.sync="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
        <a-form ref="messageForm" :model="messageInfo" :rules="rules">
          <a-row>
            <a-col :span="22">
              <a-form-item
                label="短信内容:"
                name="msgContent"
                :labelCol="{ span: 3 }"
                :wrapperCol="{ span: 21 }"
              >
                <a-textarea
                  :readonly="isCreate == 3 ? true : false"
                  v-model:value="messageInfo.msgContent"
                  placeholder="填写短信内容，格式：内容+【签名】"
                  :rows="4"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="22">
              <a-form-item
                label="接收者:"
                name="msgReciver"
                :labelCol="{ span: 3 }"
                :wrapperCol="{ span: 21 }"
              >
                <a-textarea
                  :readonly="isCreate == 3 ? true : false"
                  v-model:value="messageInfo.msgReciver"
                  placeholder="填写接收者，多个手机号码逗号间隔"
                  :rows="4"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="11">
              <a-form-item
                label="发送时间:"
                name="sendTime"
                :labelCol="formLayout2X2.labelCol"
                :wrapperCol="formLayout2X2.wrapperCol"
              >
                <a-date-picker
                  style="width: 100%"
                  placeholder="请选择发送时间"
                  v-model:value="messageInfo.sendTime"
                  :locale="locale"
                  format="YYYY-MM-DD HH:mm:ss"
                  @change="onSendTimePickerOK"
                  :disabled="isCreate == 2 ? true : false"
                />
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item
                label="是否加密:"
                name="ifEncrypt"
                :labelCol="formLayout2X2.labelCol"
                :wrapperCol="formLayout2X2.wrapperCol"
              >
                <a-radio-group
                  v-model:value="messageInfo.ifEncrypt"
                  :disabled="isCreate == 2 ? true : false"
                >
                  <a-radio :value="1">普通传输</a-radio>
                  <a-radio :value="2">加密传输</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <template #closeIcon
        ><SvgIcon iconName="sclose" className="svg201"
      /></template>
      <template #footer>
        <a-button v-show="isCreate < 3" @click="handleCancel">取消</a-button>
        <a-button v-show="isCreate < 3" type="primary" @click="handleOk"
          >确定</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script lang='js'>
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";
import {
  ref,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  defineComponent,
  watch,
} from "vue";
import { message } from "ant-design-vue";
import { saveSendInfo } from "@/api/message/send";
import SvgIcon from "@/components/Svgicon";
export default defineComponent({
  name: "MessageForm",
  components: {
    SvgIcon,
  },
  // 接受父组件传递的值
  props:{
    modalVisible:{
      type: Boolean,
      default: false,
    },
    isCreate:{
      type:Number,
      default: 0,
    },
    searchParam:{
      type:Object,
      default: () => {},
    },
    editData:{
      type:Object,
      default: () => {},
    },
  },  
  setup(props, context) {
    const messageForm = ref();
    const state = reactive({ 
      visible: false,
      confirmLoading: false,
      messageInfo:{
        id:null,
        msgContent:"",
        msgReciver:"",
        ifEncrypt:0,
        sendTime:null,
      },      
      formLayout2X2:{
        labelCol: {
              span: 6,
            },
            wrapperCol: {
              span: 18,
            },
      }
    });
    const rules = {
      msgContent: [
        { required: true, message:"短信内容不能为空", trigger: "blur" },
      ],
      msgReciver: [
        { required: true, message: "接收者手机号不能为空！", trigger: "blur" },
      ],
    };
    onBeforeMount(() => { });
    onMounted(() => {});
    const handleOk = () => {
      messageForm.value
        .validate()
        .then((success) => {
          if (success) {
            state.confirmLoading = true;
            saveSendInfo(state.messageInfo).then((resp) => {
              if (resp) {
                messageForm.value.resetFields();
                      setTimeout(() => {
                      context.emit("saveCallBack",resp.code)
                      context.emit("handleModalVisible", false);
                      state.confirmLoading = false;
                    }, 1000);
              }
            });
          } else {
            message.error("短信添加失败！！！");
            return false;
          }
        })
        .catch((err) => {
          console.log("error", err);
        });      
    };
    const handleCancel = () => {
      context.emit("handleModalVisible", false);
    };
    const onSendTimePickerOK = (date) => {
      state.messageInfo.sendTime = moment(date).format("YYYY-MM-DD HH:mm:ss");
    };
    const refData = toRefs(state);
    watch(
      () => [props.editData,props.modalVisible],
      ([nv1,nv2]) => {
        state.visible = nv2;
        state.messageInfo = nv1;
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      ...refData,
      rules,
      locale,
      handleOk,
      handleCancel,
      messageForm,
      onSendTimePickerOK,
    };
  },
});
</script>
<style lang='scss' scoped>
</style>